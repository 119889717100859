import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLTM: Record<string, any> = {
    'TITLE': 'Les Terrasses de la Marne',
    'SOUSTITLE': 'Une résidence prestigieuse en pierre de taille et aux prestations haut de gamme, livrée en 2022.',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/ltm',
    'LOGO': '../Programs/LTM/9.jpg',
    'LOCALISATION': 'Saint-Maur',
    'ADDRESS': '34 rue Kléber',
    'ADDRESSCOUNTRY': '94210 SAINT-MAUR-DES-FOSSES',
    'LAT': 48.78771080206248,
    'LONG': 2.5125636772528837,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1':
        (
            <p>
                A quelques mètres des bords de Marne, à l'angle de la rue Kléber et de l'avenue Saint-Louis, Horizon
                Grand Large a proposé le privilège de seulement 4 appartements de grand standing dans une résidence
                haut de gamme.
                <br/>
                Dans ce secteur très prisé, où se côtoient les belles villas d'époque restaurées et les habitations
                modernes de grande qualité, les Terrasses de la Marne offre au regard des passants harmonie et beauté.
                <br/>
                Remarquablement équilibrée, implantée sur une grande parcelle et dégagée de toute mitoyenneté, les
                Terrasses de la Marne est le parfait compromis entre maison et appartement.
            </p>
        ),
    'IMGONGLET1': '../Programs/LTM/9.jpg',
    'ONGLET2': 'Prestations',
    'TEXTONGLET2': (
        <>
            <p>Des prestations haut de gamme :</p>
            <ul>
                <li>Le choix de matériaux nobles et pérennes •</li>
                <li>Hall décoré en pierre marbrière •</li>
                <li>Grande hauteur sous plafond •</li>
                <li>Chape acoustique dans toutes les pièces •</li>
                <li>Ascenseur desservant tous les étages •</li>
                <li>Placards coulissants aménagés •</li>
                <li>Larges baies vitrées, double vitrage à haute performance thermique •</li>
                <li>Volets roulants électriques et centralisés dans toutes les pièces •</li>
                <li>Chaudière à gaz Wiessmann ou équivalent •</li>
                <li>Carrelages grand format et faïences Porcelanosa •</li>
                <li>Parquets stratifiés Porcelanosa •</li>
                <li>Contrôle d'accès par digicode et visiophone •</li>
            </ul>
        </>),
    'IMGONGLET2': '../Programs/LTM/10.jpg',
    'ONGLET3': 'Saint Maur (94)',
    'TEXTONGLET3': (
        <p>
            Lovée dans une boucle de la Marne, au sud-est de Paris, la commune attire de plus en plus de familles,
            séduites par son caractère paisible, authentique et dynamique. Sous le signe de la nature, Saint-Maur-des-Fossés
            cultive jalousement son air de bourgade provinciale... aux 75 000 habitants.
            <br/>
            Saint-Maur est la première ville commerçante du Val-de-Marne avec ses nombreux commerces, cafés et restaurants.
            Elle est desservie par 4 stations de RER et l'arrivée du métro en 2020 renforce son dynamisme.
        </p>),
    'IMGONGLET3': '../Villes/saint-maur.jpg',
    'GALLERY': new Set(['../Programs/LTM/1.jpg', '../Programs/LTM/2.jpg', '../Programs/LTM/3.jpg',
        '../Programs/LTM/4.jpg', '../Programs/LTM/5.jpg', '../Programs/LTM/6.jpg',
        '../Programs/LTM/7.jpg', '../Programs/LTM/8.jpg', '../Programs/LTM/9.jpg',
        '../Programs/LTM/10.jpg', '../Programs/LTM/11.jpg', '../Programs/LTM/12.jpg',
    ]),
    'PLAQUETTE': '../Programs/LTM/plaquette.pdf'
};

export const PreviewLTM = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLTM['TITLE']}
            statut={ValuesLTM['STATUT']}
            localisation={ValuesLTM['LOCALISATION']}
            logo={ValuesLTM['LOGO']}
            url={ValuesLTM['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLTM = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLTM['TITLE']}
                        sousTitle={ValuesLTM['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLTM['ONGLET1']}
                        text={ValuesLTM['TEXTONGLET1']}
                        img={ValuesLTM['IMGONGLET1']}
                        listImg={ValuesLTM['GALLERY']}
                        plaquette={ValuesLTM['PLAQUETTE']}
                    />
                    <ContentImgRight
                        title={ValuesLTM['ONGLET2']}
                        text={ValuesLTM['TEXTONGLET2']}
                        img={ValuesLTM['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesLTM['ONGLET3']}
                        text={ValuesLTM['TEXTONGLET3']}
                        img={ValuesLTM['IMGONGLET3']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLTM['LAT']}
                        long={ValuesLTM['LONG']}
                        country={ValuesLTM['ADDRESSCOUNTRY']}
                        adress={ValuesLTM['ADDRESS']}
                        currentPage={ValuesLTM['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}