import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesDK: Record<string, any> = {
    'TITLE': 'Le Domaine de Kerloudan',
    'SOUSTITLE': 'Entre terre et mer, dans un parc d\'un hectare aux arbres séculaires, une adresse d\'exception en plein centre-ville. Une résidence de 29 appartements livrée en 2021.',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/dk',
    'LOCALISATION': 'Ploemeur',
    'LOGO': '../Programs/DK/2.jpg',
    'ADDRESS': '17 allée de Kerloudan Sud',
    'ADDRESSCOUNTRY': '56270 PLOEMEUR',
    'LAT': 47.73137081599441,
    'LONG': -3.423575338626442,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': <p>
        Unique, rare, inattendu....l'impression est unanime lorsque l'on découvre le site, préservé du centre-ville et
        pourtant en plein cœur, à l'abri des regards indiscrets et du tumulte quotidien.
        <br/>
        Passée la belle grille d'origine en fer forgé, le visiteur est saisi du contraste entre l'animation du
        centre-ville et le calme qui règne dans ces lieux.
        <br/>
        Un domaine privé de plus d'un hectare. Le parc clos de murs de pierres invite à l'apaisement. Les arbres
        séculaires contribuent à la noblesse des lieux. C'est ici que se construit l'excellence dont vous rêviez...
    </p>,
    'IMGONGLET1': '../Programs/DK/1.jpg',
    'ONGLET2': 'Les appartements',
    'TEXTONGLET2':
        <p>Une architecture résolument contemporaine et la lumière comme ligne directrice ont permis au cabinet Imhotep
            de concevoir « Le Domaine de Kerloudan » et répondre aux attentes que suscite cet environnement d’exception.
            <br/>
            29 appartements d'exception : de grands espaces lumineux ouverts sur d’immenses baies vitrées, prolongées
            par de larges terrasses exposées sud, avec une vue panoramique sur ce cadre verdoyant, apaisant, et le
            privilège rare de n’avoir aucun vis-à-vis.</p>,
    'IMGONGLET2': '../Programs/DK/2.jpg',
    'ONGLET3': 'Prestations',
    'TEXTONGLET3':
        <>
            <p>Des prestations haut de gamme :</p>
            <ul className={"list-disc list-inside"}>
                <li>Séjour cathédrale au dernier étage</li>
                <li>Carrelage grand format et faïences Porcelanosa</li>
                <li>Parquets stratifiés Porcelanosa</li>
                <li>Meuble de salle de bain Porcelanosa avec applique et miroir</li>
                <li>Robinetterie Grohe</li>
                <li>WC suspendu</li>
                <li>Chaudière à gaz Viessmann ou équivalent</li>
                <li>Larges baies vitrées aluminium</li>
                <li>Pack domotique offert</li>
            </ul>
        </>,
    'IMGONGLET3': '../Programs/DK/3.jpg',
    'ONGLET4': 'Plœmeur (56)',
    'TEXTONGLET4':
        <p>
            Idéalement située en Bretagne Sud, aux portes de Lorient, la ville aux cinq ports, Plœmeur, avec ses
            20.000 habitants, est la quatrième ville du Morbihan.
            <br/>
            Commune résidentielle recherchée, Plœmeur a de quoi vous séduire : avec ses 17 km de côtes découpées et
            sauvages, ses plages de sable fin, ses criques, ses ports et ses 65 km de sentiers, les amoureux du littoral
            breton seront comblés.
            <br/>
            Destination touristique très appréciée, les activités nautiques sont légion (voile, plongée, surf, pêche...)
            <br/>
            Riche d'un patrimoine naturel et de quartiers typiques aux identités fortes, c'est entre terre et mer que
            nous vous invitons à jeter l'ancre, dans ce lieu authentique de calme et de détente pour les vacanciers ou
            les visiteurs au long cours.
        </p>,
    'IMGONGLET4': '../Villes/ploemeur.jpg',
    'GALLERY': new Set(['../Programs/DK/1.jpg', '../Programs/DK/2.jpg', '../Programs/DK/3.jpg'])
};

export const PreviewDK = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesDK['TITLE']}
            statut={ValuesDK['STATUT']}
            localisation={ValuesDK['LOCALISATION']}
            logo={ValuesDK['LOGO']}
            url={ValuesDK['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageDK = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesDK['TITLE']}
                        sousTitle={ValuesDK['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesDK['ONGLET1']}
                        text={ValuesDK['TEXTONGLET1']}
                        img={ValuesDK['IMGONGLET1']}
                        listImg={ValuesDK['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesDK['ONGLET2']}
                        text={ValuesDK['TEXTONGLET2']}
                        img={ValuesDK['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesDK['ONGLET3']}
                        text={ValuesDK['TEXTONGLET3']}
                        img={ValuesDK['IMGONGLET3']}
                    />
                    <ContentImgRight
                        title={ValuesDK['ONGLET4']}
                        text={ValuesDK['TEXTONGLET4']}
                        img={ValuesDK['IMGONGLET4']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesDK['LAT']}
                        long={ValuesDK['LONG']}
                        country={ValuesDK['ADDRESSCOUNTRY']}
                        adress={ValuesDK['ADDRESS']}
                        currentPage={ValuesDK['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}