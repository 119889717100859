import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesTyCampus: Record<string, any> = {
    'TITLE': 'Ty Campus - La résidence étudiante',
    'SOUSTITLE': '121 logements étudiants pour la rentrée 2024',
    'STATUT': 'LIVRÉE',
    'URL': '/nos-programmes/ty-campus',
    'LOCALISATION': 'Lorient',
    'LOGO': '../Programs/tyCampus/2.jpg',
    'ADDRESS': '1 rue de Lanveur',
    'ADDRESSCOUNTRY': '56100 LORIENT',
    'LAT': 47.74271338043222,
    'LONG': -3.3894064490756644,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': 'A la rentée de septembre 2024, la résidence Ty Campus accueillera 121 étudiants, sur 2 sites voisins à Lanveur. Une résidence étudiante dans l\'air du temps qui proposera divers services à ses jeunes locataires.',
    'IMGONGLET1': '../Programs/tyCampus/2.jpg',
    'ONGLET2': 'Les appartements',
    'TEXTONGLET2':
        <p>
            Ce projet réparti sur deux sites : 95 logements à la place de l’ancienne école Kerneur et 26 logements,
            juste en face, accollé au restaurant universitaire. La résidence proposera de spacieux et lumineux studios
            agencés et meublés par Atelier 87, cabinet d'architecture d'intérieur.
            <br/>
            Tous les appartements disposeront d’une baie vitrée, d’une salle d'eau et d’une cuisine. Divers services
            seront inclus, dont la présence d’un gardien en journée qui aura un rôle d’accueil, de surveillance et de
            relais administratif.
            <br/>
            Les locataires bénéficieront également d'une vaste salle de coworking, une cafétéria agrémentée d’un espace
            cuisine, une laverie et même une salle de fitness ! Possibilité de stationnements en sous-sol et locaux
            vélos.
        </p>,
    'IMGONGLET2': '../Programs/tyCampus/1.jpg',
    'ONGLET3': 'Lorient (56)',
    'TEXTONGLET3':
        <p>
            Idéalement située en Bretagne Sud, Lorient se positionne depuis plusieurs années parmis les meilleures
            villes où il fait bon vivre. Elle offre le parfait compromis pour ceux qui recherchent une ville active tout
            en bénéfiant d’un environnement calme et balnéaire. Grâce à sa grande offre culturelle, son cadre naturel et
            sa proximité avec la mer, Lorient a de quoi vous séduire.
        </p>,
    'IMGONGLET3': '../Villes/lorient.jpg',
    'GALLERY': new Set(['../Programs/tyCampus/1.jpg', '../Programs/tyCampus/2.jpg', '../Programs/tyCampus/3.jpg'
    ])
};

export const PreviewTyCampus = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesTyCampus['TITLE']}
            statut={ValuesTyCampus['STATUT']}
            localisation={ValuesTyCampus['LOCALISATION']}
            logo={ValuesTyCampus['LOGO']}
            url={ValuesTyCampus['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageTyCampus = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesTyCampus['TITLE']}
                        sousTitle={ValuesTyCampus['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesTyCampus['ONGLET1']}
                        text={ValuesTyCampus['TEXTONGLET1']}
                        img={ValuesTyCampus['IMGONGLET1']}
                        listImg={ValuesTyCampus['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesTyCampus['ONGLET2']}
                        text={ValuesTyCampus['TEXTONGLET2']}
                        img={ValuesTyCampus['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesTyCampus['ONGLET3']}
                        text={ValuesTyCampus['TEXTONGLET3']}
                        img={ValuesTyCampus['IMGONGLET3']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesTyCampus['LAT']}
                        long={ValuesTyCampus['LONG']}
                        country={ValuesTyCampus['ADDRESSCOUNTRY']}
                        adress={ValuesTyCampus['ADDRESS']}
                        currentPage={ValuesTyCampus['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}