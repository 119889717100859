import React from "react";
import {ComposantProject} from "../components";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLTPQ: Record<string, any> = {
    'TITLE': 'Les Terrasses de la Plage',
    'SOUSTITLE': '6 appartements pour une adresse d\'exception, au pied de la Grande Plage de Quiberon.',
    'LOCALISATION': 'Quiberon',
    'STATUT': 'RESIDENCE LIVREE',
    'LOGO': "../Programs/LTPQ/2.jpg",
    'URL': '/nos-programmes/ltpq',
    'ADDRESS': '4 rue de Lille',
    'ADDRESSCOUNTRY': '56170 QUIBERON',
    'LAT': 47.48095514362323,
    'LONG': -3.1189156613735576,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1':
        <p>
            Une résidence de grand standing au pied de la Grande Plage. Une architecture balnéaire des villas du début
            du XXe siècle.
            <br/>
            Les Terrasses de la Plage bénéficient d'une adresse d'exception au cœur de Quiberon, un point d'ancrage
            extraordinaire pour les vacanciers ou les visiteurs.
        </p>,
    'IMGONGLET1': '../Programs/LTPQ/1.jpg',
    'ONGLET2': 'Les appartements',
    'TEXTONGLET2': 'Seulement 6 appartements aux prestations haut de gamme. Ascenseur et garage en sous-sol. Les travaux sont achevés et les appartements livrés pour la plus grande joie des propriétaires.',
    'IMGONGLET2': '../Programs/LTPQ/3.jpg',
    'ONGLET3': 'Quiberon (56)',
    'TEXTONGLET3':
        <p>
            Située en Bretagne, dans le département du Morbihan (56), la ville de Quiberon est considérée comme une
            presqu’île.
            <br/>
            C’est le lieu idyllique pour vivre reculé des grandes villes et de la pollution. Quiberon, encerclé par la
            mer et la côte sauvage, attire de nombreux touristes tous les ans ainsi que les passionnés de sports
            nautiques. Le charme de cette ville, la tranquillité, l’espace, les paysages, les produits locaux, les
            commerces de proximité… Tout est réuni pour vivre une vie dans le calme à deux pas de la mer et des plages de
            sable blanc.
            <br/>
            Quiberon est à proximité des grandes villes : Vannes, Lorient ou encore Auray, toutes à moins d’une heure de
            route.
        </p>,
    'IMGONGLET3': '../Villes/quiberon.jpg',
    'GALLERY': new Set(['../Programs/LTPQ/1.jpg', '../Programs/LTPQ/2.jpg', '../Programs/LTPQ/3.jpg',
        '../Programs/LTPQ/4.jpg'
    ])
};

export const PreviewLTPQ = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLTPQ['TITLE']}
            statut={ValuesLTPQ['STATUT']}
            localisation={ValuesLTPQ['LOCALISATION']}
            logo={ValuesLTPQ['LOGO']}
            url={ValuesLTPQ['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLTPQ = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLTPQ['TITLE']}
                        sousTitle={ValuesLTPQ['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLTPQ['ONGLET1']}
                        text={ValuesLTPQ['TEXTONGLET1']}
                        img={ValuesLTPQ['IMGONGLET1']}
                        listImg={ValuesLTPQ['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesLTPQ['ONGLET2']}
                        text={ValuesLTPQ['TEXTONGLET2']}
                        img={ValuesLTPQ['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesLTPQ['ONGLET3']}
                        text={ValuesLTPQ['TEXTONGLET3']}
                        img={ValuesLTPQ['IMGONGLET3']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLTPQ['LAT']}
                        long={ValuesLTPQ['LONG']}
                        country={ValuesLTPQ['ADDRESSCOUNTRY']}
                        adress={ValuesLTPQ['ADDRESS']}
                        currentPage={ValuesLTPQ['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}