import {ComposantProject} from "../components";
import React from "react";
import {
    AskInfos,
    Bandeau,
    ContentImgLeft,
    ContentImgRight,
    ProgramTitle
} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const Values111: Record<string, any> = {
    'TITLE': 'Résidence - Le 111',
    'DESCRIPTION': 'Situé le long d’un axe structurant de la ville de Sceaux, le bâtiment s’intègre dans son environnement tout en répondant à un parti architectural affirmé. A l’image du projet, les logements sont lumineux et épurés profitant pour la plupart d’une vue exceptionnelle et de finitions de qualité et de haut standing.',
    'LOCALISATION': 'Sceaux',
    'LOGO': '../Programs/111/1.jpg',
    'STATUT': 'RESIDENCE LIVREE',
    'URL': '/nos-programmes/111',
    'ADDRESS': '111 rue Houdan',
    'ADDRESSCOUNTRY': '92330 SCEAUX',
    'LAT': 48.7794533126546,
    'LONG': 2.2845535925962737,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': 'La construction orientée plein sud, bénéficie d’une vue imprenable à 180 degrés sur le Parc de Sceaux, le bois de Verrières au centre et une découverte, au loin, sur les hauteurs vallonnées du bois de Meudon. Les grandes terrasses au sud ne souffrent d’aucun vis-à-vis, lesquelles dominent un jardin paysagé, engazonné, planté d’essences végétales. L’immense salon, ainsi que la suite parentale sont orientés également plein sud, bénéficiant d’un calme absolu. La vue se perd à l’horizon sur une nature baignée de lumière qui irradie partout où le regard se porte.',
    'IMGONGLET1': '../Programs/111/1.jpg',
    'ONGLET2': 'Prestations',
    'TEXTONGLET2':
        (<p>
            Le confort, l’esthétisme et la réduction des dépenses énergétiques ont guidé le choix des prestations. Le sol des appartements est entièrement revêtu de parquet massif en chêne premier choix.
            <br/>
            Les salles de bain sont habillées de faïence murale assortie d’un listel décoratif à hauteur d’huisserie.
            <br/>
            Chaque salle de bain est équipée d’un meuble vasque surmonté d’un miroir et d’une applique lumineuse. Le chauffage au gaz, la température de l’eau réglable par les robinets thermostatiques et les radiateurs sèche-serviettes apportent douceur et confort.
        </p>),
    'IMGONGLET2': '../Programs/111/2.jpg',
    'ONGLET3': 'Sceaux (92)',
    'TEXTONGLET3': <p></p>,
    'IMGONGLET3': '../Villes/sceaux.jpg',
    'GALLERY' : new Set(['../Programs/111/1.jpg', '../Programs/111/2.jpg', '../Programs/111/3.jpg', '../Programs/111/4.jpg',
        '../Programs/111/5.jpg', '../Programs/111/6.jpg','../Programs/111/7.jpg','../Programs/111/8.jpg', '../Programs/111/9.jpg'])
};


export const Preview111 = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={Values111['TITLE']}
            statut={Values111['STATUT']}
            localisation={Values111['LOCALISATION']}
            logo={Values111['LOGO']}
            url={Values111['URL']}
            detailHover={detailHover}
        />
    );
}

export const Page111 = () => {
    return (
        <>
            <BackgroundMultipleImg/>
                <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                    <div className={"w-full flex flex-col pt-20 bg-white"}>
                        <ProgramTitle
                            title={Values111['TITLE']}
                            sousTitle={Values111['SOUSTITLE']}
                        />
                        <ContentImgLeft
                            title={Values111['ONGLET1']}
                            text={Values111['TEXTONGLET1']}
                            img={Values111['IMGONGLET1']}
                            listImg={Values111['GALLERY']}
                        />
                        <ContentImgRight
                            title={Values111['ONGLET2']}
                            text={Values111['TEXTONGLET2']}
                            img={Values111['IMGONGLET2']}
                        />
                        <ContentImgLeft
                            title={Values111['ONGLET3']}
                            text={Values111['TEXTONGLET3']}
                            img={Values111['IMGONGLET3']}
                        />
                    </div>
                    <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                        <Bandeau/>
                        <AskInfos
                            lat={Values111['LAT']}
                            long={Values111['LONG']}
                            country={Values111['ADDRESSCOUNTRY']}
                            adress={Values111['ADDRESS']}
                            currentPage={Values111['TITLE']}
                        />
                        <Footer/>
                    </div>
                </div>
            </>
            );
            }
