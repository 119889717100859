import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesVRDA: Record<string, any> = {
    'TITLE': 'Villa Rue des architectes',
    'SOUSTITLE': '',
    'STATUT': 'LANCEMENT COMMERCIAL',
    'LOGO': '../Programs/VRDA/1.jpg',
    'LOCALISATION': 'Larmor-Plage',
    'URL': '/nos-programmes/vrda',
    'ADDRESS': 'Rue des architectes',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.71059716707548,
    'LONG': -3.3790162319965615,
    'ONGLET1': 'La villa',
    'TEXTONGLET1': 'A quelques minutes à pied à peine, de la grande plage de Toulhars, cette élégante villa d\'architecte  dispose de toutes les prestations de grande qualité (parquet, carrelage grand format PORCELANOSA, baie vitrée coulissante en aluminium, double vitrage auto-nettoyant à faible émissivité, volet roulant motorisé...) et des normes de plus en plus rigoureuses, notamment en matière de confort thermique, d\'isolation acoustique et d\'économie d\'énergie, qui lui confèrent tous les atouts de la modernité. En plus de son emplacement privilégié à quelques pas de la mer, cette villa baigne dans une atmosphère de calme et une sérénité grâce au parc verdoyant qui l\'entoure. \n',
    'IMGONGLET1': '../Programs/VRDA/1.jpg',
    'ONGLET2': 'Larmor-Plage (56)',
    'TEXTONGLET2': 'Au cœur de la station balnéaire de Larmor-Plage, à quelques pas de l’océan, la villa vous propose un cadre de vie harmonieux et raffiné pour satisfaire vos attentes.' +
        '\n\nAuthentique et pleine de charme, Larmor-Plage est une station du littoral qui favorise les activités balnéaires et touristiques. Ses plages de sable fin, son port de plaisance, son Casino et son front de mer ravissent toute la famille, les petits comme les grands, actifs et retraités.' +
        '\n\nLa rue des Architectes est une rue calme, à sens unique, à proximité immédiate de la plage de Toulhars et de la Nourriguel . Depuis cet emplacement rare et privilégié, tout se fait à pied. C’est en longeant la plage de Toulhars que vous rejoindrez le centre-ville et profiterez de ses nombreux commerces et restaurants, ses marchés nocturnes et dominicaux, ses équipements communaux (église, mairie, écoles, médiathèque, transports)… ',
  'IMGONGLET2': '../Villes/larmor.jpg',
    'GALLERY': new Set(['../Programs/VRDA/1.jpg'])
};

export const PreviewVRDA = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesVRDA['TITLE']}
            statut={ValuesVRDA['STATUT']}
            localisation={ValuesVRDA['LOCALISATION']}
            logo={ValuesVRDA['LOGO']}
            url={ValuesVRDA['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageVRDA = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesVRDA['TITLE']}
                        sousTitle={ValuesVRDA['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesVRDA['ONGLET1']}
                        text={ValuesVRDA['TEXTONGLET1']}
                        img={ValuesVRDA['IMGONGLET1']}
                        listImg={ValuesVRDA['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesVRDA['ONGLET2']}
                        text={ValuesVRDA['TEXTONGLET2']}
                        img={ValuesVRDA['IMGONGLET2']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesVRDA['LAT']}
                        long={ValuesVRDA['LONG']}
                        country={ValuesVRDA['ADDRESSCOUNTRY']}
                        adress={ValuesVRDA['ADDRESS']}
                        currentPage={ValuesVRDA['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}