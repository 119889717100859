import React from "react";
import {ComposantProject} from "../components";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLTP: Record<string, any> = {
    'TITLE': 'Les Terrasses de la Plage',
    'SOUSTITLE': 'Une résidence de grand standing offrant seulement 7 appartements',
    'LOCALISATION': 'Larmor-Plage',
    'STATUT': 'LANCEMENT COMMERCIAL',
    'LOGO': "../Programs/LTP/3.jpg",
    'URL': '/nos-programmes/ltp',
    'ADDRESS': '9 allée des Errants',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.70317424933006,
    'LONG': -3.390508626984916,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1':
        <p>
            C'est au calme de l'allée des Errants, à quelques pas de la plage de Locqueltas, que nous vous proposons la
            future résidence Les Terrasses de la Plage.
            <br/>
            Depuis cet emplacement rare et privilégié, tout se fait à pied. C’est en longeant les plages de Locqueltas
            et de Port-Maria que vous rejoindrez le centre-ville et profiterez de ses nombreux commerces et restaurants,
            ses marchés nocturnes et dominicaux, ses équipements communaux (église, mairie, écoles, médiathèque,
            transports)…
        </p>,
    'IMGONGLET1': '../Programs/LTP/2.jpg',
    'ONGLET2': 'Prestations',
    'TEXTONGLET2': 'Les prestations de grande qualité (parquet, carrelage grand format, baie vitrée coulissante en aluminium, double vitrage, volet roulant motorisé, interphone, digicode, hall sécurisé, ascenseur et garage privatif…), et les normes de plus en plus rigoureuses, notamment en matière de confort thermique, d’isolation acoustique et d’économie d’énergie, confèrent à la résidence tous les atouts de la modernité.',
    'IMGONGLET2': '../Programs/LTP/1.jpg',
    'ONGLET3': 'Larmor-Plage (56)',
    'TEXTONGLET3':
        <p>
          Idéalement située en Bretagne Sud, jouxtant deux des quatre grandes villes du Morbihan, aux portes de
          Lorient, Larmor-Plage jouit d'une image séduisante. Prisée des amoureux de la Bretagne, réputée pour son
          charme authentique et la beauté de ses plages, Larmor-Plage a de quoi faire rêver : la mer, le port de
          plaisance, les restaurants, les commerces, tous les services à proximité immédiate et avec pour horizon
          l'île de Groix.
          <br/>
          C'est ici que nous vous invitons à jeter l'ancre, un lieu unique de calme et de détente, ce mélange de
          bien-être et d'authenticité où la douceur de vivre s'apprécie pour les vacances ou tout au long de l'année.
        </p>,
    'IMGONGLET3': '../Villes/larmor.jpg',
    'GALLERY': new Set(['../Programs/LTP/1.jpg', '../Programs/LTP/2.jpg', '../Programs/LTP/3.jpg',
        '../Programs/LTP/4.jpg', '../Programs/LTP/5.jpg', '../Programs/LTP/6.jpg',
        '../Programs/LTP/7.png', '../Programs/LTP/8.png'
    ])
};

export const PreviewLTP = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLTP['TITLE']}
            statut={ValuesLTP['STATUT']}
            localisation={ValuesLTP['LOCALISATION']}
            logo={ValuesLTP['LOGO']}
            url={ValuesLTP['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLTP = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLTP['TITLE']}
                        sousTitle={ValuesLTP['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLTP['ONGLET1']}
                        text={ValuesLTP['TEXTONGLET1']}
                        img={ValuesLTP['IMGONGLET1']}
                        listImg={ValuesLTP['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesLTP['ONGLET2']}
                        text={ValuesLTP['TEXTONGLET2']}
                        img={ValuesLTP['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesLTP['ONGLET3']}
                        text={ValuesLTP['TEXTONGLET3']}
                        img={ValuesLTP['IMGONGLET3']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLTP['LAT']}
                        long={ValuesLTP['LONG']}
                        country={ValuesLTP['ADDRESSCOUNTRY']}
                        adress={ValuesLTP['ADDRESS']}
                        currentPage={ValuesLTP['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}