import React from "react";
import {MapPinIcon} from "lucide-react";
import {PreviewLTP} from "./Program/ltp";
import {PreviewTyCampus} from "./Program/ty_campus";
import {PreviewLQI} from "./Program/lqi";
import {PreviewJK} from "./Program/jk";
import {PreviewAlicia} from "./Program/alicia";
import {PreviewLTL} from "./Program/ltl";
import {PreviewLTM} from "./Program/ltm";
import {PreviewLarmorVillage} from "./Program/larmorVillage";
import {Preview111} from "./Program/111";
import {PreviewQuelisoy} from "./Program/quelisoy";
import {PreviewLTT} from "./Program/ltt";
import {PreviewLTPH} from "./Program/ltph";
import {PreviewDK} from "./Program/dk";
import {PreviewLTPQ} from "./Program/ltpq";
import {Card} from "react-bootstrap";
import {cn} from "../../lib/utils";
import {PreviewLJT} from "./Program/ljt";
import {PreviewVRDA} from "./Program/vrda";

export function TitleProjects() {
  return (
      <>
        <h2 className={"p-1 text-[#022855] font-semibold lg:text-5xl md:text-4xl text-3xl text-center"}>Découvrir
          nos programmes</h2>
        <div className={"bg-[#022855] h-1 w-52 rounded-full"}></div>
      </>
  );
}

export function Projects() {
  return (
      <div className={"grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 p-2 gap-2"}>

        {/*Lancement commercial*/}
        <PreviewLJT detailHover={true}/>
        <PreviewVRDA detailHover={true}/>
        <PreviewLTP detailHover={true}/>
        {/*En construction*/}
        <PreviewLQI detailHover={true}/>
        <PreviewJK detailHover={true}/>
        <PreviewAlicia detailHover={true}/>
        {/*Livré*/}
        <PreviewTyCampus detailHover={true}/>
        <PreviewLTL detailHover={true}/>
        <PreviewLTM detailHover={true}/>
        <PreviewDK detailHover={true}/>
        <PreviewLarmorVillage detailHover={true}/>
        <PreviewLTPQ detailHover={true}/>
        <Preview111 detailHover={true}/>
        <PreviewQuelisoy detailHover={true}/>
        <PreviewLTT detailHover={true}/>
        <PreviewLTPH detailHover={true}/>
      </div>
  );
}

export function ComposantProject(
    {
      title, localisation, logo, url, statut, detailHover
    }: {
      title: string,
      localisation: string,
      logo: string,
      url?: string,
      statut: string,
      detailHover: boolean
    }
) {
  return (
      <Card className={cn({
        "w-full rounded-none [&>*:last-child]:opacity-0 [&>*:last-child]:hover:opacity-100 overflow-hidden": true,
        "h-[400px] [&>*:first-child]:hover:scale-125 border-none": detailHover,
        "h-full": !detailHover
      })}
      >
        <ContentComposantProject
            detailHover={detailHover}
            title={title}
            localisation={localisation}
            logo={logo}
            statut={statut}
            url={url}
        />
      </Card>
  );
}

export function ContentComposantProject(
    {
      title, localisation, logo, url, statut, detailHover
    }: {
      title: string,
      localisation: string,
      logo: string,
      url?: string,
      statut: string,
      detailHover: boolean
    }
) {
  return (
      <>
        <Card.Img variant="top" src={logo} className={cn({
          "rounded-none object-cover": true,
          "h-[200px] ": !detailHover,
          "h-[400px] transition-all duration-1000": detailHover
        })}/>
        {!detailHover && (
            <>
              <Card.Body>
                <Card.Text className={" text-center flex flex-col items-center gap-4"}>
                  <div>
                    <span
                        className={"text-xs font-semibold text-[#022855] uppercase"}>{statut}</span>
                  </div>
                  <div
                      className={"flex-grow-1 flex-col flex items-center justify-center gap-2 h-full"}>
                    <div>
                      <h1 className={"text-xl font-primary"}>{title}</h1>
                    </div>
                    <div className={"flex flex-row items-center"}>
                      <MapPinIcon className={"h-4 w-4"}/>
                      <span>{localisation}</span>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
              <div
                  className="z-50 absolute bg-[#022855]/50 flex items-center justify-center w-full h-full transition-all duration-1000">
                <div>
                  <a href={url}>
                    <button
                        className={"border-1 border-white text-white px-4 py-2 bg-[#283b57]/90"}>
                      En savoir plus
                    </button>
                  </a>
                </div>
              </div>
            </>
        )}
        {detailHover && (
            <>
              <div
                  className="z-50 absolute flex items-start justify-end w-full h-full p-1 text-xs font-semibold text-white uppercase ">
                <div className={"bg-[#022855] p-2"}>
                  {statut}
                </div>
              </div>
              <a href={url}
                 className="md:invisible z-50 absolute text-white flex flex-col justify-between items-start w-full h-full p-2">

                <div className={"flex flex-row items-center"}>
                  <MapPinIcon className={"h-4 w-4"}/>
                  <span>{localisation}</span>
                </div>
                <span className={"text-xl font-primary"}>{title}</span>
              </a>
              <a href={url}
                 className="z-50 absolute bg-[#022855]/50 text-white flex flex-col justify-between items-start w-full h-full p-2 transition-opacity duration-1000">

                <div className={"flex flex-row items-center"}>
                  <MapPinIcon className={"h-4 w-4"}/>
                  <span>{localisation}</span>
                </div>
                <span className={"text-xl font-primary"}>{title}</span>
              </a>
            </>
        )}
      </>
  );
}
