import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesJK: Record<string, any> = {
    'TITLE': 'Les Jardins de Kerloudan',
    'SOUSTITLE': 'Villa de standing, en cœur de ville, dans un parc intimiste',
    'STATUT': 'EN CONSTRUCTION',
    'URL': '/nos-programmes/jk',
    'LOCALISATION': 'Ploemeur',
    'LOGO': '../Programs/JK/4.jpg',
    'ADDRESS': 'Sud, 17 All. de Kerloudan',
    'ADDRESSCOUNTRY': '56270 PLOEMEUR',
    'LAT': 47.73137081599441,
    'LONG': -3.423575338626442,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': 'L\'impression est unanime lorsque l\'on découvre le site, préservé du centre-ville et pourtant en plein cœur, à l\'abri des regards indiscrets et du tumulte quotidien. Passée la belle grille d\'origine en fer forgé, le visiteur est saisi du contraste inattendu entre l\'animation du centre-ville et le calme qui règne en ces lieux... Tout se fait à pied, commerces, écoles, collèges, piscine, salle de spectacles, équipements sportifs dans un cadre apaisé où l\'élégance est de mise.',
    'IMGONGLET1': '../Programs/JK/4.jpg',
    'ONGLET2': 'Ploemeur (56)',
    'TEXTONGLET2':
        <p>Résolument tournée vers l'avenir, Plœmeur possède tous les atouts d'un développement ambitieux et harmonieux.
            Le centre-ville historique propose de nombreux commerces, cafés et restaurants. Son marché animé et apprécié
            bien au-delà de la population locale.
            <br/>
            Les nombreux équipements sportifs et culturels ajoutent à l'art de vivre. La commune dispose d'un magnifique
            golf surplombant la mer, d'une piscine ludique et d'une programmation culturelle diversifiée à la salle de
            spectacles Océanis. Médiathèque, salles de sports, centre équestre, accrobranche..., complètent cette offre
            diversifiée d'activités et de loisirs.</p>,
    'IMGONGLET2': '../Villes/ploemeur.jpg',
    'GALLERY': new Set(['../Programs/JK/1.jpg', '../Programs/JK/2.jpg', '../Programs/JK/3.jpg', '../Programs/JK/4.jpg'])
};

export const PreviewJK = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesJK['TITLE']}
            statut={ValuesJK['STATUT']}
            localisation={ValuesJK['LOCALISATION']}
            logo={ValuesJK['LOGO']}
            url={ValuesJK['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageJK = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesJK['TITLE']}
                        sousTitle={ValuesJK['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesJK['ONGLET1']}
                        text={ValuesJK['TEXTONGLET1']}
                        img={ValuesJK['IMGONGLET1']}
                        listImg={ValuesJK['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesJK['ONGLET2']}
                        text={ValuesJK['TEXTONGLET2']}
                        img={ValuesJK['IMGONGLET2']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesJK['LAT']}
                        long={ValuesJK['LONG']}
                        country={ValuesJK['ADDRESSCOUNTRY']}
                        adress={ValuesJK['ADDRESS']}
                        currentPage={ValuesJK['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}