import {ComposantProject} from "../components";
import React from "react";
import {AskInfos, Bandeau, ContentImgLeft, ContentImgRight, ProgramTitle} from "./components";
import {Footer} from "../../../Components/footer";
import {BackgroundMultipleImg} from "../../../Components/background";

export const ValuesLTT: Record<string, any> = {
    'TITLE': 'Les Terrasses de Toulhars',
    'SOUSTITLE': 'Une résidence de standing de 9 appartements, dans le quartier recherché de Toulhars à Larmor-Plage, livrée en 2018.',
    'STATUT': 'RESIDENCE LIVREE',
    'LOGO': '../Programs/LTT/1.jpg',
    'LOCALISATION': 'Larmor-Plage',
    'URL': '/nos-programmes/ltt',
    'ADDRESS': '12 rue des Oeillets',
    'ADDRESSCOUNTRY': '56260 LARMOR-PLAGE',
    'LAT': 47.71151804600393,
    'LONG': -3.378281645494232,
    'ONGLET1': 'La résidence',
    'TEXTONGLET1': 'Une résidence à l’architecture contemporaine.\n\nUn très bel emplacement, à quelques pas de la très belle plage de Toulhars. Cette réalisation signée de l’agence d’architecture SADOU, aux lignes contemporaines bénéficiant d’aménagements extérieurs soigneusement conçus, s’intègre parfaitement dans son environnement.\n\nRésidence de standing à l’architecture épurée, « Les Terrasses de Toulhars » se compose de 9 appartements haut de gamme du T2 au T5 bénéficiant tous de grandes terrasses ou jardins orientés au sud. Idéalement agencés et très lumineux, les appartements disposent de grandes pièces de vie et de larges baies vitrées. \n\nSeuls les plus réactifs ont désormais le privilège d’habiter cette résidence haut de gamme livrée par Horizon Grand Large au cours du deuxième semestre 2017. Vendu à 100% avant même le démarrage des travaux…',
    'IMGONGLET1': '../Programs/LTT/1.jpg',
    'ONGLET2': 'Les appartements',
    'TEXTONGLET2': 'Les prestations de grande qualité (parquet, carrelage grand format PORCELANOSA, baie vitrée coulissante en aluminium thermolaqué, double vitrage auto-nettoyant à faible émissivité, volet roulant motorisé, interphone, digicode, hall sécurisé, ascenseur et garage privatif en sous-sol…), et les normes de plus en plus rigoureuses, notamment en matière de confort thermique, d’isolation acoustique et d’économie d’énergie, confèrent à la résidence tous les atouts de la modernité.',
    'IMGONGLET2': '../Programs/LTT/2.jpg',
    'ONGLET3': 'Larmor-Plage (56)',
    'TEXTONGLET3':
        <p>
            Idéalement située en Bretagne Sud, jouxtant deux des quatre grandes villes du Morbihan, aux portes de
            Lorient, Larmor-Plage jouit d'une image séduisante. Prisée des amoureux de la Bretagne, réputée pour son
            charme authentique et la beauté de ses plages, Larmor-Plage a de quoi faire rêver : la mer, le port de
            plaisance, les restaurants, les commerces, tous les services à proximité immédiate et avec pour horizon
            l'île de Groix.
            <br/>
            C'est ici que nous vous invitons à jeter l'ancre, un lieu unique de calme et de détente, ce mélange de
            bien-être et d'authenticité où la douceur de vivre s'apprécie pour les vacances ou tout au long de l'année.
        </p>,
    'IMGONGLET3': '../Villes/larmor.jpg',
    'GALLERY': new Set(['../Programs/LTT/1.jpg', '../Programs/LTT/2.jpg'])
};

export const PreviewLTT = ({detailHover}: { detailHover: boolean }) => {
    return (
        <ComposantProject
            title={ValuesLTT['TITLE']}
            statut={ValuesLTT['STATUT']}
            localisation={ValuesLTT['LOCALISATION']}
            logo={ValuesLTT['LOGO']}
            url={ValuesLTT['URL']}
            detailHover={detailHover}
        />
    );
}

export const PageLTT = () => {
    return (
        <>
            <BackgroundMultipleImg/>
            <div className={"z-50 w-full min-h-screen flex flex-col justify-between items-center pt-10"}>
                <div className={"w-full flex flex-col pt-20 bg-white"}>
                    <ProgramTitle
                        title={ValuesLTT['TITLE']}
                        sousTitle={ValuesLTT['SOUSTITLE']}
                    />
                    <ContentImgLeft
                        title={ValuesLTT['ONGLET1']}
                        text={ValuesLTT['TEXTONGLET1']}
                        img={ValuesLTT['IMGONGLET1']}
                        listImg={ValuesLTT['GALLERY']}
                    />
                    <ContentImgRight
                        title={ValuesLTT['ONGLET2']}
                        text={ValuesLTT['TEXTONGLET2']}
                        img={ValuesLTT['IMGONGLET2']}
                    />
                    <ContentImgLeft
                        title={ValuesLTT['ONGLET3']}
                        text={ValuesLTT['TEXTONGLET3']}
                        img={ValuesLTT['IMGONGLET3']}
                    />
                </div>
                <div className={"w-full flex flex-col bg-opacity-75 bg-[#022855] "}>
                    <Bandeau/>
                    <AskInfos
                        lat={ValuesLTT['LAT']}
                        long={ValuesLTT['LONG']}
                        country={ValuesLTT['ADDRESSCOUNTRY']}
                        adress={ValuesLTT['ADDRESS']}
                        currentPage={ValuesLTT['TITLE']}
                    />
                    <Footer/>
                </div>
            </div>
        </>
    );
}